import React from "react"
import DashboardLayout from "./dashboard-layout"

const DashboardProfile = ({ location }) => (
  <>
    <DashboardLayout location={location} pageSubtitle="profile">
      <div className="dashboard-content">Profile</div>
    </DashboardLayout>
  </>
)

export default DashboardProfile
