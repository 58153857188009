import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import DashboardHome from "../components/app/dashboard-home"
import DashboardBooking from "../components/app/dashboard-booking"
import DashboardSettings from "../components/app/dashboard-settings"
import DashboardProfile from "../components/app/dashboard-profile"
import NotFound from "../components/notfound"
import PrivateRoute from "../components/shared/privateroute"

const Dashboard = ({ location }) => {
  return (
    <Layout currPage="dashboard" location={location}>
      <Router basepath={"/dashboard"}>
        <PrivateRoute
          loginLink="/login"
          path="/"
          component={DashboardHome}
          location={location}
        />
        <PrivateRoute
          loginLink="/login"
          path="/profile"
          component={DashboardProfile}
          location={location}
        />
        <PrivateRoute
          loginLink="/login"
          path="/booking/tutor/:tutorid"
          component={DashboardBooking}
          tab_main={0}
          tab_newBooking={0}
          location={location}
        />
        <PrivateRoute
          loginLink="/login"
          path="/booking"
          component={DashboardBooking}
          tab_main={1}
          tab_newBooking={0}
          location={location}
        />
        <PrivateRoute
          loginLink="/login"
          path="/settings"
          component={DashboardSettings}
          location={location}
        />
        <NotFound default />
      </Router>
    </Layout>
  )
}
export default Dashboard
