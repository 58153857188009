import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import useAuth from "../../util/useStrapiAuth"

const PrivateRoute = ({
  component: Component,
  location,
  loginLink,
  ...rest
}) => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== loginLink) {
      navigate(loginLink, { state: { redirect: location.pathname } })
      return null
    }
  }, [isAuthenticated, loginLink, location.pathname])

  return <Component {...rest} location={location} />
}
export default PrivateRoute
