import React from "react"
import DashboardLayout from "./dashboard-layout"

const DashboardHome = ({ location }) => (
  <DashboardLayout location={location} pageSubtitle="home">
    <div className="dashboard-content">
      <p>Welcome to your Dashboard!</p>
    </div>
  </DashboardLayout>
)

export default DashboardHome
