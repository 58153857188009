import React from "react"
import DashboardLayout from "./dashboard-layout"

const DashboardSettings = ({ location }) => (
  <>
    <DashboardLayout location={location} pageSubtitle="settings">
      <div className="dashboard-content">Settings</div>
    </DashboardLayout>
  </>
)

export default DashboardSettings
