import React, { useState } from "react"
import Datetime from "react-datetime"
import { Helmet } from "react-helmet"
import DashboardLayout from "./dashboard-layout"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

function valid(current) {
  let isValid =
    current.day() !== 0 &&
    current.day() !== 6 &&
    !current.isBetween("2021-05-19", "2021-05-25", "days", "[]")
  return isValid
}

class MyDTPicker extends React.Component {
  render() {
    return (
      <div>
        <Datetime
          open={true}
          timeFormat={false}
          closeOnClickOutside={false}
          closeOnTab={false}
          input={false}
          isValidDate={valid}
        />
      </div>
    )
  }
}

const DashboardBooking = ({ location, tab_main, tab_newBooking, tutorid }) => {
  const [tabIndex_main, setTabIndex_main] = useState(tab_main)
  const [tabIndex_newBooking, setTabIndex_newBooking] = useState(tab_newBooking)
  return (
    <>
      <Helmet>
        <link href="/css/react-datetime.css" rel="stylesheet" />
      </Helmet>
      <DashboardLayout location={location} pageSubtitle="booking">
        <Tabs
          selectedIndex={tabIndex_main}
          onSelect={index => setTabIndex_main(index)}
        >
          <TabList>
            <Tab>new booking</Tab>
            <Tab>already booked lessons</Tab>
          </TabList>
          <TabPanel>
            <Tabs
              selectedIndex={tabIndex_newBooking}
              onSelect={index => setTabIndex_newBooking(index)}
            >
              <TabList>
                <Tab>1. Select tutor</Tab>
                <Tab disabled>2. Select date & time</Tab>
                <Tab disabled>3. Further details</Tab>
                <Tab disabled>4. Wait for confirmation</Tab>
              </TabList>
              <TabPanel>
                <div>Tutors:</div>
                <div>{tutorid}</div>
              </TabPanel>
              <TabPanel>
                <MyDTPicker />
              </TabPanel>
              <TabPanel>Details:</TabPanel>
              <TabPanel>
                Your class booking had not been confirmed yet.
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>bookings:</TabPanel>
        </Tabs>
        <p />
      </DashboardLayout>
    </>
  )
}

export default DashboardBooking
